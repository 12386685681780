    /* You can add global styles to this file, and also import other style files */
    /*palette*/
    
    @import '~@angular/material/theming';
    @include mat-core();
    $nau-fe-primary: mat-palette($mat-indigo);
    $nau-fe-accent: mat-palette($mat-pink, A200, A100, A400);
    $nau-fe-warn: mat-palette($mat-red);
    $nau-fe-theme: mat-light-theme(( color: ( primary: $nau-fe-primary, accent: $nau-fe-accent, warn: $nau-fe-warn, )));
    @include angular-material-theme($nau-fe-theme);
    @import './palette_media.scss';
    /*Fonts*/
    
    @font-face {
        font-family: "Proxima Nova";
        src: url('./assets/fonts/Proxima-Nova-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Proxima Nova";
        src: url('./assets/fonts/Proxima-Nova.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Proxima Nova";
        src: url('./assets/fonts/Proxima-Nova-Semibold.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Proxima Nova";
        src: url('./assets/fonts/Proxima-Nova-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    /**/
    
    $nau-fe-typography: mat-typography-config($font-family: "Proxima Nova,sans-serif");
    @include angular-material-typography($nau-fe-typography);
    /**/
    
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        line-height: 1.428571429;
    }
    
    body {
        font-family: Proxima Nova, sans-serif;
    }

    p, label{
        font-weight: 100;
    }
    
     ::selection {
        background: #c2d501;
    }
    /*global css*/
    
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
    
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        @media (min-width: 992px) {
            max-width: 970px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 750px;
        }
        @media (min-width: 1200px) {
            max-width: 1366px;
        }
    }
    
    .body-content {
        @media (min-width: 768px) {
            min-height: calc(100vh - 460px);
        }
    }
    
    h5,
    .h5 {
        font-size: 14px;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul {
        margin: 0 0 30px 0;
    }
    
    h1 {
        letter-spacing: .2em!important;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    strong {
        font-weight: 500;
    }
    
    .col-md-offset-1 {
        @media (min-width: 992px) {
            margin-left: 8.333333333333332%;
        }
    }
    
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    
    .col-md-6 {
        @media (min-width: 992px) {
            width: 50%;
        }
    }
    
    .btn-primary {
        border: 1px solid #000;
        background-color: #000;
        color: #fff!important;
        padding: 15px 30px;
        @media screen and (min-width: 990px) {
            padding: 15px 40px;
        }
    }
    
    .btn-primary-border {
        border: 1px solid #000!important;
        color: #000!important;
        background-color: #fff;
        padding: 15px 30px;
        @media screen and (min-width: 990px) {
            padding: 15px 40px;
        }
    }
    
    .btn {
        border-radius: 0;
        border: 0;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        line-height: 1.428571429;
        @include md {
            width: 100%;
        }
        @include sm {
            width: 100%;
        }
    }
    
    .btn:hover {
        opacity: 0.88;
    }
    
    .btn:not(.btn-link) {
        @media screen and (min-width: 990px) {
            width: auto;
        }
    }
    
    .btn-link,
    .btn-link:link,
    .btn-link:visited {
        padding: 0 0 5px 0;
        border-bottom: 1px solid #000;
        color: #000;
        text-decoration: none;
    }
    
    .mbottom,
    .mbottom-double {
        margin-bottom: 30px;
    }
    
    input.form-control {
        outline: none;
    }
    
    .form-control {
        margin: 0;
        border: 1px solid #aaa;
        border-radius: 0;
        box-shadow: none;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
    
    h2,
    .h2 {
        font-size: 30px;
    }
    
    .flex33 {
        width: 33%;
        @include sm {
            width: 100%;
        }
        @include md {
            width: 100%;
        }
    }
    
    //spinner-loader
    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #000;
    }