/* Colors */

$darkgrey: #373737;
$darkblue: #191d27;
$lightgrey: #cccccc;
$primary: #00008F;
$tabcolor: #f4f4f4;
$background-color: #fafafa;

/**/

///////////
// MEDIA //
///////////
@mixin sm {
    @media only screen and (max-width: 540px) {
        @content;
    }
}

@mixin xss {
    @media only screen and (max-width: 280px) {
        @content;
    }
}

@mixin xssm {
    @media only screen and (max-width: 320px) {
        @content;
    }
}

@mixin xsm {
    @media only screen and (min-width: 321px) and (max-width: 420px) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: 541px) and (max-width: 768px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: 800px) and (max-width: 900px) {
        @content;
    }
}